<template>
  <div class="auth">
    <div class="auth__img"><img :src="require(`@/assets/images/logo.png`)" alt="logo" /></div>
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="observer" class="auth__content">
      <h2 class="title">Password Reset</h2>
      <p>Set up a new password</p>
      <section class="form">
        <div class="form__row">
          <div class="form__row_col">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              :rules="{ required: true, verify_password: true, max: 255 }"
              v-slot="{ errors }"
              name="password"
            >
              <PasswordInput
                :class="{ 'form-control--invalid': error }"
                placeholder="Enter your new password"
                type="password"
                name="password"
                :validateError="errors[0] || error"
                v-model="password"
                :element="password"
                @input="password = $event"
              ></PasswordInput>
            </ValidationProvider>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              rules="required|confirmed:password|min: 8|max: 255"
              v-slot="{ errors, valid }"
            >
              <PasswordInput
                :class="{ 'form-control--valid': valid }"
                placeholder="Confirm your new password"
                type="password"
                :validateError="errors[0]"
                v-model="password_confirmation"
                :element="password_confirmation"
                @input="password_confirmation = $event"
              ></PasswordInput>
            </ValidationProvider>
          </div>
        </div>
        <BaseButton
          class="button--admin button--full-width button--green"
          text="Save"
          :loading="loading"
          :disabled="invalid"
          @click="save"
        ></BaseButton>
      </section>
    </ValidationObserver>
    <div class="auth__footer" style="padding: 15px 40px">
      <p>© Collekt 2022</p>
      <ul>
        <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    PasswordInput,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false,
    };
  },
  created() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("auth", {
      error: "getError",
    }),
  },
  methods: {
    ...mapActions("auth", ["apiResetPassword"]),
    ...mapMutations("auth", ["setError"]),
    async save() {
      this.loading = true;
      await this.apiResetPassword({
        email: this.$route.query.email,
        forgot_password: +this.$route.query.reset_password ? 0 : 1,
        password: this.password,
        password_confirmation: this.password_confirmation,
      });
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.auth {
  &__content {
    flex: 1;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    h2,
    p {
      text-align: center;
      color: $black;
    }
    p {
      @include type($fs: 16px, $lh: 1.18, $fw: 500);
      margin-top: 12px;
      margin-bottom: 30px;
    }
    .button {
      margin-top: 35px;
    }
  }
  &__img {
    img {
      width: 150px;
      margin: 6vh auto 8.5vh;
    }
  }
}
</style>
